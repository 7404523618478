@import '../../assets/styles/variables.scss';
@import '../../assets/styles/_mixins.scss';

.flex-gap-10 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.flex-col-gap-10 {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
}

//TextInput.js
.input-label-div {
  position: relative;
  overflow: hidden;
  margin: 10px 0;
  height: 2.5rem;
  .inp-label {
    position: absolute;
    top: 0.5rem;
    font-size: 0.8rem;
    left: 10px;
    color: rgba(0, 0, 0, 0.5);
    transition: all 0.2s;
    pointer-events: none;
  }
  .inp {
    width: 100%;
    border-radius: 5px;
    height: 100%;
    bottom: 0;
    padding-top: 2%;
    padding-left: 8px;
    font-size: 0.8rem;
    /* transition: all linear 0.1s; */
    border: solid 1px rgba(27, 9, 9, 0.5);
  }

  .inp:disabled + .inp-label {
    outline: none;
    color: black;
    top: 0;
    font-size: 0.7rem;
  }
  .inp:not(:placeholder-shown) + .inp-label {
    // /* if placeholder is shown - meaning - no value in input */
    outline: none;
    color: black;
    top: 0;
    font-size: 0.7rem;
  }

  .inp:focus + .inp-label {
    outline: none;
    color: black;
    top: 0;
    font-size: 0.7rem;
  }
}

//Loader.js
.loader {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  place-items: center;
  justify-content: center;
  z-index: 10000;
  top: 0;
  // left: 50%;
  background-color: rgba(white, 0.6);
  svg {
    color: white !important;
  }
}

.custom-loaders {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

//Loader.js
.testimonials-loader {
  padding: 10rem 0;
  display: block;
  width: 100%;
  height: 100%;
  display: flex;
  place-items: center;
  justify-content: center;
  z-index: 10000;
  svg {
    color: $primaryColor !important;
  }
}

// DropdownTextInput.js

.search-box {
  width: 100%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;

  .search-entry {
    height: auto;
    width: auto !important;
    color: black;
    font-size: 13px;
    background-color: rgb(218, 217, 217);
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
  }
  .selected-entry {
    width: auto;
    height: auto;
    background-color: rgb(204, 81, 81);
    color: white;
  }
}

.tags-input {
  display: block;
  align-items: flex-start;
  flex-wrap: wrap;
  min-height: 48px;
  width: 100%;
  padding: 0 8px;
  border: 1px solid rgb(214, 216, 218);
  border-radius: 6px;

  &:focus-within {
    border: 1px solid #0052cc;
  }

  .input {
    flex: 1;
    border: none;
    outline: none;
    height: 35px;
    font-size: 12px;
    padding: 0;

    &:focus {
      outline: transparent;
    }
  }
}

#tags {
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin: 8px 0 0 0;
}

.tag-div {
  width: auto;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 8px;
  font-size: 13px;
  list-style: none;
  border-radius: 6px;
  margin: 0 7px 7px 0;
  background: rgb(45, 163, 199);

  .tag-title {
    color: rgb(255, 255, 255);
    //margin-top: 3px;
  }

  .tag-close-icon {
    display: block;
    width: 16px;
    height: 16px;
    line-height: 14px;
    text-align: center;
    font-size: 14px;
    margin-left: 8px;
    color: #0052cc;
    border-radius: 50%;
    background: rgb(255, 255, 255);
    cursor: pointer;
  }
}

.tag-input-container {
  display: flex;
  align-items: center;
  gap: 10px;
}
.tag-text-input {
  padding: 10px;
  width: 60%;
  height: 70px;
}
.tag-type-selector {
  padding: 0;
  margin: 0;
  height: 52px;
  width: 300px;
}

.add-tag-button {
  background-color: #ff8308;
  color: white;
  padding: 15px 20px;
  border-radius: 8px;
  width: 10%;
}

.tagsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-bottom: 20px;
}

@include mobile {
  .tag-input-container {
    flex-direction: column;
  }
  .add-tag-button {
    width: 100%;
  }

  .tag-text-input {
    width: 100%;
  }
}

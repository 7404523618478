$phone-width: 576px;
$tablet-width: 767px;
$desktop-width: 992px;
$xl-width: 1200px;

@mixin mobile {
  @media (max-width: $phone-width) {
    @content;
  }
}

@mixin landscape {
  @media (max-width: $tablet-width) {
    @content;
  }
}

@mixin tablet {
  @media (max-width: $desktop-width) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktop-width) {
    @content;
  }
}

@mixin xl-desktop {
  @media (min-width: $xl-width) {
    @content;
  }
}

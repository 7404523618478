@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_mixins.scss';

$pageHorizontalWidth: 25px;
$leftSectionWidth: 20%;

.noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.solve-assignment {
  @include mobile {
    flex-direction: coloumn;
    border: 1px;
    width: 100%;
    padding: 8px;
  }

  .title {
    font-size: 1.2rem;
    font-weight: 700;
    text-align: center;
  }

  .assignment-details {
    display: flex;

    justify-content: space-between;
    border-bottom: 1px solid $primaryColor;
    padding: 0;

    @include mobile {
      flex-direction: column;
    }

    .assignment-description {
      @include mobile {
        padding: 10px;
        background-color: $lightBackground;
        border-radius: 10px;
      }
    }

    .assignment-select-section {
      width: $leftSectionWidth;
      border-right: 1px solid #cbbaba;
      padding-right: 10px;
      padding-bottom: 10px;

      @include mobile {
        width: 100%;
        border-right: 0px;
        padding-bottom: 10px;
        text-align: center;
        padding-right: 0px;
      }

      .MuiSelect-selectMenu {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }

    .content {
      width: 100% - $leftSectionWidth;
      flex-grow: 1;
      padding: 0 15px;

      @include mobile {
        .timer-div {
          flex-direction: column;
          justify-content: center;
          align-items: center;

          .points-div {
            text-align: center;
          }
        }

        width: 100%;
        margin: 10px 0px;
      }

      .ql-editor {
        padding: 0;
        color: gray;
        padding-bottom: 10px;
      }
    }
  }

  .body {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;

    @include mobile {
      flex-direction: column;
    }

    .left-div {
      padding-right: 10px;
      width: $leftSectionWidth;
      // overflow-y: scroll;
      border-right: 1px solid #cbbaba;
      position: relative;

      @include mobile {
        width: 100%;
        overflow-y: hidden;
        border-right: none;
      }

      h3 {
        padding: 10px 20px;
      }

      .task {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 0;
        overflow-wrap: break-word;
        width: 100%;

        p {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          margin: 5px 0;
          font-size: 0.9rem;
          text-align: left;
        }

        &:hover {
          background-color: $primaryColorLight;
          transition: all 0.5s;
        }

        .status {
          width: 25px;
          height: 25px;
        }

        .completed {
          color: green;
        }

        .pending {
          color: $dangerColor;
        }
      }

      .selected {
        background-color: rgba($primaryColor, 0.1);
      }
    }

    .task-section {
      padding: 10px 10px;
      min-height: 70vh;
      width: 100% - $leftSectionWidth;

      @include mobile {
        justify-content: center;
        padding: 0px;
        padding-top: 5px;
        width: 100%;

        .header {
          flex-direction: column;
          align-items: center;
          padding: 0px;
          background: null;
          text-align: center;
        }
      }

      .header {
        display: flex;
        background: #c4c4c433;

        justify-content: space-between;
        padding: 20px;
        border-radius: 35px;
      }

      .tags {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        padding: 0 20px;

        p {
          // border: 1px solid $secondaryColor;
          padding: 5px;
          color: $secondaryColor;
          font-weight: bold;
          font-size: 0.85rem;
          border-radius: 10px;
          margin: 0 5px;
        }
      }

      .description {
        // border-bottom: 1px solid;
        // padding: 10px;
        // margin: auto;
        // text-align: center;
        // justify-content: center;
        // align-items: center;

        @include mobile {
          padding: 10px;
          background-color: $lightBackground;
          border-radius: 10px;
          border: none;
          margin-top: 10px;
        }
      }

      .actions {
        position: relative;
        padding: 10px 20px;
        border-bottom: 1px solid black;

        h4 {
          margin: 0;
          font-size: 1.2rem;
          flex-grow: 1;
          font-weight: 700;
          text-align: center;
        }

        .quill {
          margin: 10px 0;
          background-color: rgba($primaryColorLight, 0.025);

          .ql-container {
            min-height: 150px;
          }
        }
      }

      @include mobile {
        min-height: 40vh;
      }
    }
  }

  @include mobile {
    .submission-details {
      // padding: 0 18px;
      .mt {
        margin-top: 33px;
      }
    }
  }
}

.questionnaire {
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  .questions {
    height: 90%;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0 5px;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      background: $lightBackground;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: rgba($primaryColor, 0.4);
      border-radius: 15px;
      cursor: pointer;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  .task-question {
    background-color: rgb(250, 250, 250);
    border-radius: 10px;
    padding: 20px;
    margin-top: 10px;

    textarea {
      border: 1px solid #e6e6e6;
      padding: 5px;
    }
  }

  @include mobile {
    min-height: auto;
    max-height: auto;

    .top-bar {
      height: auto;
      justify-content: center;
    }

    .questions {
      padding: 0;
      height: auto;
    }
  }
}

.p10 {
  padding: 20px;
}

.assignment-leaderboard {
  width: 30%;
  // background-color: rgb(214, 218, 217);
  background-color: $tertiaryColor;

  h3 {
    text-align: center;
    padding: 10px 0;
    color: white;
    text-transform: uppercase;
    font-weight: 300;
    font-family: 'Noto Sans';
    letter-spacing: 1px;
  }

  .content {
    .entry {
      display: grid;
      grid-template-columns: 0.45fr 3fr 2fr 1fr;
      padding: 10px 5px;

      p {
        font-size: 0.9rem;
        letter-spacing: 1px;
        text-transform: capitalize;
        margin: auto 0;

        font-family: 'Patrick Hand';
        color: white;
      }

      .img {
        display: grid;
        place-items: center;
        color: white;
        font-weight: bold;
        font-size: 0.9rem;
        text-transform: uppercase;
        width: 25px;
        height: 25px;
        border-radius: 50%;
      }

      &:nth-child(odd) {
        background-color: rgb(99, 98, 98);
      }
    }

    .first {
      width: 100%;
      display: flex;
      flex-direction: column;
    }
  }

  @include mobile {
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
  }
}

.white {
  color: white;
}

// TimerComponent.js
.timer-component {
  padding: 10px;
  text-align: center;
  font-family: 'Noto Sans';
  font-size: 0.95rem;
  font-weight: 500;
  position: relative;
  max-width: 250px;
  display: flex;

  .time {
    color: inherit;
    font-size: 1.3rem;
  }

  small {
    font-weight: bold;
    color: inherit;
  }

  svg {
    fill: inherit;
    width: 1.3rem;
    height: 1.3rem;
    margin: 0 5px;
    transition: all 0.1s;
  }

  h3 {
    display: flex;

    align-items: center;
    justify-content: center;
    font-size: 1rem;
    margin: 5px;
    color: inherit;
    font-family: 'Noto Sans';
  }

  .hover-component {
    position: absolute;
    background: rgba(gray, 0.9);
    border-radius: 5px;
    // width: 100%;
    height: fit-content;
    top: 0;
    max-width: 250px;
    width: fit-content;
    transition: all 0.5s;
    opacity: 0;

    * {
      color: whitesmoke;
    }
  }
}

.task-timer {
  padding: 10px;
  text-align: center;
  font-family: 'Noto Sans';
  font-size: 0.95rem;
  font-weight: 500;
  position: relative;
  max-width: 250px;
  display: flex;
  align-items: center;

  .time {
    color: inherit;
    font-size: 1.1rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    svg {
      fill: rgb(255, 151, 0);
      width: 1.3rem;
      height: 1.3rem;
      margin: 0 5px;
      transition: all 0.1s;
    }
  }

  small {
    font-weight: bold;
    color: inherit;
  }

  h3 {
    display: flex;

    align-items: center;
    justify-content: center;
    font-size: 1rem;
    margin: 5px;
    color: inherit;
    font-family: 'Noto Sans';
  }

  .hover-component {
    position: absolute;
    background: rgba(gray, 0.9);
    border-radius: 5px;
    // width: 100%;
    height: fit-content;
    top: 0;
    max-width: 250px;
    width: fit-content;
    transition: all 0.5s;
    opacity: 0;

    * {
      color: whitesmoke;
    }

    svg {
      fill: rgb(168, 168, 168) !important;
    }
  }
}

// Hints.js

.hints {
  // position: absolute;
  // right: 0;

  button {
    img,
    svg {
      width: 20px;
      height: 20px;
      fill: $secondaryColor;
      margin: 0 5px;
    }
  }
}

.hint-div {
  width: 100%;
  position: relative;
  overflow-wrap: break-word;

  header {
    display: flex;

    button {
      border-collapse: separate;
      padding: 10px;
      font-size: 0.85rem;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 0.95rem;
        height: 0.95rem;
        margin: auto 5px;
        fill: $newPrimaryColor;
      }
    }

    .selected {
      background: linear-gradient(
        90deg,
        $newPrimaryColor 0%,
        $newSecondaryColor 87.19%
      );
      color: white;
      font-weight: bold;

      svg {
        fill: white;
      }
    }
  }

  .content {
    border-top: 1px solid $secondaryColor;

    .locked {
      text-align: center;

      h3 {
        font-size: 1rem;
      }

      p {
        font-size: 0.9rem;
      }
    }
  }

  .loader {
    position: absolute;
    fill: red;
    color: red;
  }

  @include mobile {
    width: 70vw;
  }
}

.task-video-player {
  width: 1000px;
  height: 600px;

  @include mobile {
    width: 800px;
    height: 300px;
  }
}

.new-task-section {
  background-color: #ffffff !important;
  max-height: 90vh;
  min-height: 90vh;

  .assignment-timer {
    min-height: 10vh;
    max-height: 10vh;
    border-bottom: 1px solid rgb(241, 241, 241);
    display: flex;
    align-items: center;

    .timer-div {
      min-width: 55%;
      max-width: 55%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .timer-div-inner {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;
        background-color: #fafafa;
        border-radius: 5px;

        .timer-component {
          padding-right: 0;
        }
      }
    }

    .submit-test-btn {
      min-width: 45%;
      max-width: 45%;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .new-btn {
        width: 20%;
        margin-right: 20px;
      }
    }

    @include mobile {
      justify-content: space-between;
      padding: 0 10px;

      .timer-div {
        justify-content: flex-start;
        min-width: 45%;
        max-width: 45%;

        .timer-div-inner {
          justify-content: center;
          width: 100%;
        }
      }

      .submit-test-btn {
        min-width: 55% !important;
        max-width: 55% !important;
        justify-content: flex-end;
        align-items: center;

        .new-btn {
          margin-right: 0;
          width: auto !important;
          font-size: 70% !important;
        }
      }
    }
  }

  .webcam-container {
    border-radius: 5px;
    width: 10vw;
    height: 10vh;
    margin-right: 1rem;
    // position: fixed;
    // right: 10%;
    // top: 10vh;
    z-index: 100000;

    .webcam {
      width: 100%;
      height: 100%;
      margin-right: 0.5rem;
    }

    @include mobile {
      width: 30vw;

      video {
        width: 100% !important;
      }
    }
  }

  .body {
    display: flex;
    flex-grow: 1;
    background-color: #ffffff !important;

    .left-div-react-compiler {
      width: 30% !important;
    }

    .left-div {
      min-width: 2%;
      overflow: hidden;
      min-height: 90vh;
      max-height: 90vh;
      background-color: #ffffff !important;
      position: relative;
      z-index: 5;
      width: 40%;

      .section {
        height: 100%;
        border-right: 1px solid rgb(219, 219, 219);
        display: flex;
        flex-direction: column;
        justify-content: center;

        .section_header {
          height: 10%;
          border-bottom: 1px solid rgb(219, 219, 219);
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .tabs {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            grid-column-gap: 1rem;
            padding: 0 10px;

            .tab {
              padding: 0.5rem 1rem;
              border-radius: 0.5rem;
              cursor: pointer;
              transition: all 0.3s;

              &:hover {
                background-color: rgb(233, 233, 233);
              }
            }

            .selected {
              background-color: rgb(233, 233, 233);
            }
          }
        }

        .content {
          height: 82%;
          padding: 10px;
          overflow: auto;

          h1,
          h2,
          h3,
          h4,
          h5,
          b,
          strong {
            font-weight: 500 !important;
          }

          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            background: $lightBackground;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: rgba($primaryColor, 0.4);
            border-radius: 15px;
            cursor: pointer;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #555;
          }

          .task-details {
            .task-name {
              padding: 5px 0 10px 0;
              font-size: 16px;
              font-weight: 500;
            }

            .header-details {
              background-color: #f5f5f5;
              border-radius: 10px;
              padding: 10px;
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-gap: 10px;
              margin-top: 10px;
            }

            .tags {
              display: flex;
              flex-wrap: wrap;
              align-items: center;

              .tag {
                margin: 10px;
                padding: 5px 10px;
                border-radius: 25px;
                background-color: #ffb8b8;
                font-size: 12px;
              }
            }

            .description {
              margin-top: 1%;

              .title {
                margin-top: 2%;
                font-size: 16px;
                font-weight: 500;
              }

              .ql-editor {
                margin-top: 2%;
                padding: 0 !important;
              }
            }
          }
        }

        .switch-tasks {
          border-top: 1px solid rgb(219, 219, 219);
          height: 8%;
          padding: 10px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background-color: #ffffff !important;

          .all-problems {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 14px;
            border: 1px solid rgb(201, 201, 201);
            border-radius: 5px;
            padding: 3px 5px;
            color: rgb(85, 85, 85);

            svg {
              margin-right: 5px;
              width: 20px;
            }
          }

          .switch-btns {
            display: flex;
            justify-content: center;
            align-items: center;
            grid-column-gap: 1rem;

            .tasks-count {
              color: rgb(124, 124, 124);
              font-weight: 500;
            }

            .arrow-btn {
              background-color: rgb(231, 231, 231);
              border-radius: 25%;
              padding: 5px;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;

              svg {
                width: 20px;
                height: 20px;
                fill: rgb(53, 53, 53);
              }

              &:disabled {
                svg {
                  fill: rgb(53, 53, 53);
                }

                background-color: rgb(245, 245, 245);
                cursor: not-allowed;
              }
            }
          }
        }
      }
    }

    .right-div {
      position: relative;
      background-color: #ffffff !important;
      overflow: auto;
      min-height: 90vh;
      max-height: 90vh;
      width: 100%;

      z-index: 1;

      .expand-button {
        position: absolute;
        left: 0px;
        background-color: $primaryColor;
        border-radius: 50%;
        padding: 0;
        left: 0;
        top: 5px;
        z-index: 100;
        width: 20px;
        height: 20px;
        display: grid;
        place-items: center;

        svg {
          fill: white;
          width: 15px;
          height: 15px;
        }
      }

      .compiler {
        background-color: #ffffff !important;
        min-height: 100%;
        max-height: 100%;

        // background-color: #cbbaba;
        .compiler-options {
          display: flex;
          justify-content: space-between;
          height: 10%;
          padding: 0 5px;

          svg {
            width: 20px;
            height: 20px;
            cursor: pointer;
          }
        }

        .code-editor {
          min-height: 74vh !important;
          max-height: 74vh !important;
        }

        .actions {
          height: 10%;
        }
      }

      .output {
        padding: 10px;

        textarea {
          border-radius: 10px;
          padding: 5px;
        }
      }

      .actions {
        background-color: #ffffff !important;

        // margin-left: 40px;
        .learn-and-solve {
          min-height: 60vh;

          form {
            width: 100%;

            // min-height: 60vh;
            // max-height: 60vh;
            .quill {
              min-height: 60vh;
              max-height: 60vh;
            }

            .ql-editor {
              overflow-y: auto;
              min-height: 55vh;
              max-height: 55vh;

              &::-webkit-scrollbar {
                width: 5px;
              }

              &::-webkit-scrollbar-track {
                background: $lightBackground;
              }

              /* Handle */
              &::-webkit-scrollbar-thumb {
                background: rgba($primaryColor, 0.4);
                border-radius: 15px;
                cursor: pointer;
              }
            }

            .ql-container {
              min-height: 55vh;
              max-height: 55vh;
            }
          }
        }

        // padding: 10px;
        .start-task-div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          padding: 2rem;
          min-height: 80vh;

          img {
            width: 20%;
            height: 20%;
          }

          // .new-btn {
          //   width: 30%;
          // }

          .start-task-timer-text {
            padding: 2rem;
            font-size: 110%;
            font-weight: 500;
            margin-top: 1rem;
            width: 90%;
            text-align: center;
          }
        }

        .my-answer-div {
          display: flex;
          flex-direction: column;
          justify-content: center;
          padding: 10px;

          .my-answer {
            min-height: 80vh;
            max-height: 80vh;
            background-color: rgb(243, 243, 243);
            overflow: auto;
            border-radius: 10px;

            &::-webkit-scrollbar {
              width: 5px;
            }

            &::-webkit-scrollbar-thumb {
              background: rgba($primaryColorLight, 0.35);
            }
          }
        }
      }
    }

    @include mobile {
      flex-direction: column;

      padding: 10px;

      .left-div {
        width: 100%;
        overflow: auto;
        min-height: auto;
        max-height: none;
        border-bottom: 1px solid rgb(219, 219, 219);
        padding: 0 0 20px 0;

        .header-content {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          padding: 10px;
          grid-column-gap: 10px;

          .task-head-div {
            width: 70%;
          }

          .all-problems {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            font-size: 14px;
            border: 1px solid rgb(201, 201, 201);
            border-radius: 5px;
            padding: 3px 5px;
            color: rgb(85, 85, 85);

            svg {
              margin-right: 5px;
              width: 20px;
            }
          }
        }

        .task-name {
          padding: 0;
          font-size: 18px;
          font-weight: 500;
        }

        .task-timer {
          padding: 5px 0 !important;
        }

        .content {
          height: auto;
          padding: 0 5px 20px 5px;
          overflow: auto;

          border-radius: 15px;

          h1,
          h2,
          h3,
          h4,
          h5,
          b,
          strong {
            font-weight: 500 !important;
          }

          &::-webkit-scrollbar {
            width: 5px;
          }

          &::-webkit-scrollbar-track {
            background: $lightBackground;
          }

          /* Handle */
          &::-webkit-scrollbar-thumb {
            background: rgba($primaryColor, 0.4);
            border-radius: 15px;
            cursor: pointer;
          }

          /* Handle on hover */
          &::-webkit-scrollbar-thumb:hover {
            background: #555;
          }

          .header-details {
            background-color: #f5f5f5;
            border-radius: 10px;
            padding: 10px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 10px;
            // margin-top: 10px;
          }

          .task-details {
            background: #fff2e9;
            margin-top: 3%;
            border-radius: 15px;
            padding: 10px;

            .tags {
              display: flex;
              flex-wrap: wrap;
              align-items: center;

              .tag {
                margin: 10px;
                padding: 5px 10px;
                border-radius: 25px;
                background-color: #ffb8b8;
                font-size: 12px;
              }
            }

            .description {
              margin-top: 1%;

              .title {
                margin-top: 2%;
                font-size: 16px;
                font-weight: 500;
              }

              .ql-editor {
                margin-top: 2%;
                padding: 0 !important;
              }
            }
          }
        }
      }

      .right-div {
        padding: 10px;
        width: 100%;
        overflow: hidden;
        max-height: 120vh;

        .compiler {
          background-color: #ffffff !important;
          min-height: auto;
          max-height: auto;
          width: 100%;

          // background-color: #cbbaba;
          .compiler-options {
            display: flex;
            justify-content: space-between;
            height: 10%;
            padding: 10px 5px;

            svg {
              width: 20px;
              height: 20px;
              cursor: pointer;
            }
          }

          .code-editor {
            min-height: 74vh !important;
            max-height: 74vh !important;
            border-radius: 10px;
          }

          .actions {
            height: 10%;
          }
        }

        .output {
          padding: 10px;

          textarea {
            border-radius: 10px;
            padding: 5px;
          }
        }

        .time-bounded {
          .new-btn {
            width: 100% !important;
          }
        }

        .actions {
          background-color: #ffffff !important;

          // padding: 10px;

          .start-task-div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 2rem;

            .new-btn {
              width: 50%;
            }

            img {
              width: 20%;
              height: 20%;
            }

            .start-task-timer-text {
              padding: 2rem;
              font-size: 110%;
              font-weight: 500;
              margin-top: 1rem;
              width: 100%;
              text-align: center;
            }
          }

          .my-answer-div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            padding: 10px;

            .my-answer {
              min-height: auto;
              max-height: auto;
              background-color: rgb(243, 243, 243);
              overflow: auto;
              border-radius: 10px;
            }
          }
        }
      }
    }
  }
}

.all-tasks {
  display: flex;
  flex-direction: column;
  font-size: 14px;

  .back-to-assignment {
    display: flex;
    // justify-content: flex-start;
    align-items: center;
    font-size: 14px;
    color: rgb(85, 85, 85);
    // width: 100%;
    z-index: 10000000;
    border: 1px solid rgb(201, 201, 201);
    border-radius: 5px;
    margin-left: 10px;

    svg {
      margin: 0;
      margin-right: 5px;
      width: 25px;
    }
  }

  &::-webkit-scrollbar {
    width: 5px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba($primaryColorLight, 0.5) !important;
  }

  &::-webkit-scrollbar-track {
    background: rgba($primaryColorLight, 0.15) !important;
  }

  .tasks {
    width: 100%;

    .task-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
      overflow-wrap: break-word;
      width: 100%;
      border-bottom: rgb(240, 240, 240) 1px solid;

      p {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin: 5px 0;
        font-size: 0.9rem;
        text-align: left;
      }

      img {
        width: 20px;
        height: 20px;
      }

      &:hover {
        background-color: $primaryColorLight;
        transition: all 0.5s;
      }

      .status {
        width: 25px;
        height: 25px;
      }

      .completed {
        color: green;
      }

      .pending {
        color: $dangerColor;
      }
    }

    .selected-task {
      background-color: rgba($primaryColor, 0.1);
    }
  }
}

.css-1v1ta9s-MuiPaper-root-MuiDrawer-paper {
  &::-webkit-scrollbar {
    width: 5px !important;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba($primaryColorLight, 0.5) !important;
  }

  &::-webkit-scrollbar-track {
    background: rgba($primaryColorLight, 0.15) !important;
  }
}

.time-table {
  display: flex;

  p {
    padding: 10px;
    vertical-align: text-top;
    margin: 0;
  }

  .right {
    border-left: 1px solid #b2c2c9;
  }

  .grid-section {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .time {
    padding: 15px;
    color: #b2a5a5;
    border-bottom: 1px solid #b2c2c9;
    border-right: 1px solid #b2c2c9;
  }

  .selected {
    color: #30dd14;
    font-weight: bold;
  }

  .booked {
    color: #dd1414;
    font-weight: bold;
  }

  .heading-1 {
    background: #668c9c;
    color: white;
    text-decoration: underline;
    text-align: center;
  }

  .heading-2 {
    background: #c8dfe9;
    text-align: center;
  }
}

.table-heading {
  font-weight: 300;
  font-size: 20px;
  color: $primaryColor;
  text-align: center;
  padding: 24px 0;
}

.interview-timing-confirmation {
  margin: 20px 40px 10px 40px;
  max-width: 630px;

  .input-section {
    display: flex;
    align-items: center;
    margin-top: 20px;

    img {
      width: 32px;
    }

    input {
      box-sizing: border-box;
      border: 1px solid #7d8c99;
      border-radius: 10px;
    }
  }

  .time-slots {
    display: flex;
    align-items: center;
    margin: 35px 0 0px 0px;

    .time {
      color: #30dd14;
      margin: 0px 20px;
    }
  }
}

.code-package-executor {
  display: flex;
  width: 100%;
  padding-left: 10px;
  height: 82vh;
  max-height: 82vh;

  .preview {
    width: 50%;

    .sp-preview {
      height: 82vh;
      max-height: 82vh;
    }
  }

  .left-section {
    display: flex;
    max-width: 50%;
    width: 50%;
    border-right: 1px solid grey;

    .file-explorer {
      width: 30%;
      max-height: 82vh;
      overflow: scroll;
      border-right: 1px solid grey;
    }

    .code-viewer {
      overflow: scroll;
      scrollbar-width: 0;
      flex-grow: 1;
      border-bottom: 1px solid grey;
    }

    .test-case-executor {
      height: 30%;
      overflow: scroll;
    }
  }
}

.blue-btn {
  background-color: blue;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
  color: white;
  display: block;
  text-align: center;
}

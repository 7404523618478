@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_mixins.scss';

.questions-page {
  .header {
    padding: 30px 20px 0 60px;

    h3 {
      font-size: 22px;
      font-weight: 400;
    }
  }

  .questions-page-content {
    display: grid;
    grid-template-columns: 1fr 3fr;
    padding: 10px 30px 30px 30px;

    .filters-div {
      position: sticky;
      top: $navHeight;

      .filters {
        padding: 10px 10px 25px 10px;
        margin: auto;
        align-items: center;
        margin-top: 50px;
        display: block;
        width: 85%;
        // height: 50vh;
        border-radius: 15px;
        background-color: $secondaryText;
        // background-color: rgb(149, 191, 204);

        .select-div {
          margin-top: 15px;

          h3 {
            font-size: 17px;
            font-weight: 400;
          }

          select {
            outline: none;
            margin-top: 5px;
            padding: 5px;
            border-radius: 10px;
            width: 100%;
          }
        }
      }
    }

    .question-forum {
      .question-form {
        background-color: $primaryColor;
        display: grid;
        padding: 0 10px;
        grid-template-columns: 4fr 1fr;
        justify-content: space-between;

        .submit-btn {
          background-color: $secondaryColor;
          color: white;
          margin: auto;
          border-radius: 5px;
          padding: 10px;
        }
      }

      .questions-div {
        h3 {
          text-align: center;
        }
      }

      .question-div {
        border-bottom: 2px solid;

        .question {
          padding: 20px;
          text-align: left;

          h4,
          p {
            font-family: 'Noto Sans';
          }

          .btn-div {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .btns {
              margin-top: 10px;
              display: flex;
              justify-content: flex-end;
              align-items: center;
            }
          }

          .flex-div {
            font-size: 14px;
            margin-bottom: 10px;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .assignment-name {
              border-radius: 10px;
              background-color: $primaryColorLight;

              padding: 5px 10px;

              p {
                color: white;
                padding: 5px 0;
              }
            }
          }

          .answered {
            display: flex;
            justify-content: flex-end;
            color: white;
            margin-bottom: 10px;
            text-align: right;

            p {
              display: flex;
              grid-column-gap: 3px;
              font-size: 13px;
              border-radius: 10px;
              background-color: rgb(23, 141, 23);
              padding: 2px 10px;
              color: white;
              align-items: center;

              svg {
                font-size: 15px;
                fill: white;
              }
            }
          }

          .btn-div-content {
            display: flex;
            justify-content: flex-start;

            align-items: center;

            .votes {
              svg {
                width: 35px;
                height: 35px;
              }

              button {
                display: flex;
                font-size: 0.8rem;
                align-items: center;
                font-weight: bold;
                padding: 0;
              }
            }

            .answer {
              margin: 0 50px;
              display: flex;
              align-items: center;

              svg {
                width: 1rem;
                height: 1rem;
                margin: 0 5px;
              }
            }

            .active {
              svg {
                fill: $secondaryColor;
              }
            }
          }
        }

        .answer-div {
          width: 100%;
          position: relative;
          border-top: 1px solid gray;
          padding: 10px;
          // background-color: $primaryColor;

          .quill {
            width: 100%;
            background-color: white;
            height: 100%;
          }

          ul {
            padding: 10px;
            list-style: none;
          }

          .correct {
            background-color: rgb(135, 232, 135);
            border-radius: 10px;
            border: none;
          }

          .answer {
            margin: 5px 0;
            border-bottom: 1px solid gray;
            padding: 10px;

            .mark {
              padding: 5px 5px 10px 5px;
              display: flex;
              justify-content: flex-start;
              grid-column-gap: 3px;
              align-items: center;
            }

            .user {
              display: flex;
              align-items: center;

              svg {
                width: 1.2rem;
                height: 1.2rem;
              }

              font-size: 0.9rem;
              position: relative;
              padding-bottom: 5px;
              border-bottom: 1px solid black;
            }

            .content-div {
              display: flex;
              justify-content: space-between;
              align-items: center;
            }

            .content {
              display: flex;
              align-items: center;
              justify-content: flex-start;
              padding: 0;

              .votes {
                margin: 0 5px;

                button {
                  display: flex;
                  font-size: 0.8rem;
                  align-items: center;
                  font-weight: bold;
                  padding: 0;
                  height: 100%;
                }
              }

              .active {
                svg {
                  fill: $secondaryColor;
                }
              }
            }
          }
        }
      }
    }
  }
}

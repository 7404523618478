@import './assets/styles/_variables.scss';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Aleo:ital,wght@0,300;1,700&display=swap');
// font-family: 'Aleo', cursive;
// Inter
// font-family: 'Noto Sans', sans-serif;

* {
  scroll-behavior: smooth;
  // font-family: 'Noto Sans', sans-serif;
  font-family: 'Inter', sans-serif;
  margin: 0;
  // color: $text;
}

body {
  background: $background;
}

input {
  background: $background;
  border: 1px solid black;
}

button {
  background: none;
  border: none;
  cursor: pointer;
}

a {
  text-decoration: none;
}

table {
  border: 1px solid #c2bcbc;
  width: 100%;
  border-collapse: collapse;

  tr {
    border-bottom: 2px solid #e6e3e3;
    cursor: pointer;

    &:hover {
      background-color: $lightBackground;
    }
  }

  th {
    color: $primaryColor;
  }

  td,
  th {
    text-align: center;
    padding: 15px;
  }

  td {
    font-size: 0.9rem;
  }
}

.fw500 {
  font-weight: 500;
}

.primary-btn {
  background-color: $primaryColor;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
  color: white;
  display: block;
  text-align: center;
}

.new-btn {
  background-color: $primaryColor;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
  color: white;
  display: block;
  text-align: center;
}

.secondary-btn {
  background-color: $secondaryColor;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
  color: white;
  display: block;
  text-align: center;
}

.error {
  color: red;
  font-weight: bolder;
  font-size: 0.8rem;
  padding: 5px;
}

.success {
  fill: $primaryColor !important;
  color: $primaryColor !important;
}

.danger {
  fill: $dangerColor !important;
  color: $dangerColor !important;
}

.red-btn {
  background-color: $dangerColor;
  padding: 10px;

  border-radius: 5px;

  color: white;
}

.Toastify__toast-body {
  color: white;
}

.ql-tooltip {
  z-index: 5;
}

.text-2xl {
  font-size: xx-large;
}

.text-xl {
  font-size: x-large;
}

.globalInput {
  height: 30px;
  padding-left: 10px;
  border: 1px solid gainsboro;
}

.gap-10 {
  gap: 10px;
}

.addStrikes {
  table {
    tr {
      cursor: default;

      &:hover {
        background-color: white !important;
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.Toastify__toast-container {
  z-index: 20000;
}

.questions-page .header {
  padding: 30px 20px 0 60px;
}

.questions-page .header h3 {
  font-size: 22px;
  font-weight: 400;
}

.questions-page .questions-page-content {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: 1fr 3fr;
  grid-template-columns: 1fr 3fr;
  padding: 10px 30px 30px 30px;
}

.questions-page .questions-page-content .filters-div {
  position: -webkit-sticky;
  position: sticky;
  top: 10vh;
}

.questions-page .questions-page-content .filters-div .filters {
  padding: 10px 10px 25px 10px;
  margin: auto;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 50px;
  display: block;
  width: 85%;
  border-radius: 15px;
  background-color: #b0b3b8;
}

.questions-page .questions-page-content .filters-div .filters .select-div {
  margin-top: 15px;
}

.questions-page .questions-page-content .filters-div .filters .select-div h3 {
  font-size: 17px;
  font-weight: 400;
}

.questions-page
  .questions-page-content
  .filters-div
  .filters
  .select-div
  select {
  outline: none;
  margin-top: 5px;
  padding: 5px;
  border-radius: 10px;
  width: 100%;
}

.questions-page .questions-page-content .question-forum .question-form {
  background-color: #8c4848;
  display: -ms-grid;
  display: grid;
  padding: 0 10px;
  -ms-grid-columns: 4fr 1fr;
  grid-template-columns: 4fr 1fr;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-form
  .submit-btn {
  background-color: #8c4848;
  color: white;
  margin: auto;
  border-radius: 5px;
  padding: 10px;
}

.questions-page .questions-page-content .question-forum .questions-div h3 {
  text-align: center;
}

.questions-page .questions-page-content .question-forum .question-div {
  border-bottom: 2px solid;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .question {
  padding: 20px;
  text-align: left;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .question
  h4,
.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .question
  p {
  font-family: 'Noto Sans';
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .question
  .btn-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .question
  .btn-div
  .btns {
  margin-top: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .question
  .flex-div {
  font-size: 14px;
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .question
  .flex-div
  .assignment-name {
  border-radius: 10px;
  background-color: #82b6ed;
  padding: 5px 10px;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .question
  .flex-div
  .assignment-name
  p {
  color: white;
  padding: 5px 0;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .question
  .answered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
  color: white;
  margin-bottom: 10px;
  text-align: right;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .question
  .answered
  p {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  grid-column-gap: 3px;
  font-size: 13px;
  border-radius: 10px;
  background-color: #178d17;
  padding: 2px 10px;
  color: white;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .question
  .answered
  p
  svg {
  font-size: 15px;
  fill: white;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .question
  .btn-div-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .question
  .btn-div-content
  .votes
  svg {
  width: 35px;
  height: 35px;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .question
  .btn-div-content
  .votes
  button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.8rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: bold;
  padding: 0;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .question
  .btn-div-content
  .answer {
  margin: 0 50px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .question
  .btn-div-content
  .answer
  svg {
  width: 1rem;
  height: 1rem;
  margin: 0 5px;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .question
  .btn-div-content
  .active
  svg {
  fill: #8c4848;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .answer-div {
  width: 100%;
  position: relative;
  border-top: 1px solid gray;
  padding: 10px;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .answer-div
  .quill {
  width: 100%;
  background-color: white;
  height: 100%;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .answer-div
  ul {
  padding: 10px;
  list-style: none;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .answer-div
  .correct {
  background-color: #87e887;
  border-radius: 10px;
  border: none;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .answer-div
  .answer {
  margin: 5px 0;
  border-bottom: 1px solid gray;
  padding: 10px;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .answer-div
  .answer
  .mark {
  padding: 5px 5px 10px 5px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  grid-column-gap: 3px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .answer-div
  .answer
  .user {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 0.9rem;
  position: relative;
  padding-bottom: 5px;
  border-bottom: 1px solid black;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .answer-div
  .answer
  .user
  svg {
  width: 1.2rem;
  height: 1.2rem;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .answer-div
  .answer
  .content-div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .answer-div
  .answer
  .content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  padding: 0;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .answer-div
  .answer
  .content
  .votes {
  margin: 0 5px;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .answer-div
  .answer
  .content
  .votes
  button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 0.8rem;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: bold;
  padding: 0;
  height: 100%;
}

.questions-page
  .questions-page-content
  .question-forum
  .question-div
  .answer-div
  .answer
  .content
  .active
  svg {
  fill: #8c4848;
}

$navHeight: 10vh;

$darkText: #000000;
$text: black;
$secondaryText: #b0b3b8;
$lightText: white;
$tertiaryColor: #8c4848;

$background: #ffffff;
$lightBackground: #e7e8d1;
$lighterBackground: #e5e5e5;
$greyBackground: rgb(172, 172, 172);

$primaryColor: #097770;
$newPrimaryColor: #097770;
$primaryColorLight: #82b6ed;

$gradientGreen: #2e4b53;
$gradientGreenBackground: #294450;

$secondaryColor: #ff8308;
$newSecondaryColor: #ff8308;

$dangerColor: #f22955;

$paddingHorizontal: 20px;
$navBarHeight: 60px;

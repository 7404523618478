@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_mixins.scss';

.login-page,
.register-page,
.mobile-number-page,
.verify-otp-page,
.reset-password-page {
  height: 100vh;
  @include mobile {
    background-size: cover;
    background-position: center;
    min-height: 90vh;
    display: grid;
  }

  .MuiOutlinedInput-root {
    position: relative;
    border-radius: 18px;
    height: 54px;
    width: 90%;
  }

  .MuiOutlinedInput-inputAdornedStart {
    padding-left: 15px;
    padding-right: 20px;
  }

  @include mobile {
    .MuiOutlinedInput-root {
      position: relative;
      border-radius: 25px;
      height: 60px;
      width: 289px;
    }

    #footer-text {
      // font-family: 'Poppins';
      font-style: normal;
      font-weight: 500;
      font-size: 21px;
      line-height: 36px;
    }
  }

  .form {
    width: 100%;
    height: 100%;
    background-color: white;
    display: flex;
    height: 100vh;
    // overflow: hidden;
    // align-items: center;
    position: fixed;

    .left {
      padding: 20px;
      // width: 750px;
      width: 50%;
      form {
        display: flex;
        flex-direction: column;
        height: 100%;
      }
      .logo {
        display: flex;
        justify-content: center;
        align-items: center;
        // margin: 20px 0;
        width: 100%;
        height: 20%;
        // margin: auto;

        img {
          width: 40px;
          height: 60px;
        }
        @include mobile {
          img {
            margin: 0%;
            padding: 0%;
            width: 40px;
            height: 75px;
          }
        }
      }
      .login,
      .signup,
      .forgot,
      .reset,
      .verify-mobile,
      .verify-otp {
        height: 80%;
        padding-left: 60px;
        padding-bottom: 40px;
        // align-items: center;
        display: flex;
        flex-direction: column;
        .head-div {
          width: 90%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          @include mobile {
            width: 100%;
            flex-direction: column;
          }
          .head-div-left {
            .login-title {
              margin-bottom: 5px;
              color: black;
              font-style: normal;
              font-weight: 500;
              font-size: 24px;
            }
            .sub-head {
              color: rgba(58, 58, 58, 0.7);
              font-size: 18px;
              padding: 10px 0px;
              font-weight: 500;
            }
          }
        }
        .input-div {
          .email-input,
          .name-input,
          .verify-mobile-input,
          .verify-otp-input,
          .forgot-input {
            padding: 0;
            margin-top: 10px;
            .email-head,
            .name-head,
            .verify-mobile-head,
            .verify-otp-head {
              font-weight: 500;
              font-size: 20px;
              padding: 10px 0px;
            }
            .verify-otp-head {
              line-height: 30px;
            }
            .otp-input {
              padding: 30px 0;
              display: flex;
              justify-content: flex-start;
              @include mobile {
                justify-content: center;
              }
            }
            .verify-otp-head {
              @include mobile {
                padding: 10px 50px 30px 50px;
                line-height: 30px;
              }
            }
            .email-in {
              width: 80%;
              border-radius: 50%;

              @include mobile {
                height: 60px;
                align-items: center;
              }
            }
          }
          .password-input,
          .passout-input {
            padding: 5px 0;
            .password-head,
            .passout-head {
              font-weight: 500;
              font-size: 20px;
              font-style: normal;
              padding: 10px 0px;
            }
            .password-in {
              width: 80%;
              border-radius: 50%;

              @include mobile {
                height: 60px;
                align-items: center;
              }
            }
          }
        }

        .forget-password-link {
          padding: 5px 0;
          a {
            font-size: 13px;
          }
        }
        .btn-div {
          padding: 5px 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          // .button:hover {
          //   background: rgb(220, 67, 67);
          // }

          @include mobile {
            justify-content: center;
          }
        }
        a {
          font-weight: 500;
          background: linear-gradient(
            90deg,
            $newPrimaryColor 0%,
            $newSecondaryColor 87.19%
          );
          text-decoration: none;
          background-clip: text;
          -webkit-text-fill-color: transparent;
          -webkit-background-clip: text;
        }
        .not-recieved-otp {
          display: flex;
          justify-content: flex-start;
          @include mobile {
            justify-content: center;
          }
        }
        .login-with-another-account {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .loggedin-email {
            font-size: 95%;
            margin-left: 1%;
            padding: 5px 0;
            color: #777777;
          }
          @include mobile {
            align-items: center;
          }
        }
        // .sign-up-link {
        //   color: rgba(235, 31, 31, 0.765);
        //   font-weight: bold;
        // }
        @include mobile {
          padding-left: 0px;
          margin-bottom: 70px;
        }
      }

      @include mobile {
        width: 100%;
        height: 100%;
        padding-left: 0px;
        padding-right: 0px;
      }

      img {
        height: 100%;
        width: 100%;
      }
      .dont-have-acc-footer {
        font-style: normal;
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        margin-top: 10px;
      }
    }

    .right {
      width: 50%;
      display: flex;
      // flex-direction: column;
      // align-items: center;
      padding: 15px;
      // background: linear-gradient(
      //   200deg,
      //   $newPrimaryColor 0%,
      //   $newSecondaryColor 87.19%
      // );
      background-color: #f7f7f7;
      color: white;
      height: 100%;

      @include mobile {
        display: none;
      }

      // @media (max-width: 1150px) {
      //   font-size: 2.2vmax;
      //   display: none;
      // }

      .head {
        display: flex;
        text-align: center;
        justify-content: center;
        padding: 0rem 1rem;
        h4 {
          width: 70%;
          // font-family: 'Roboto';
          font-weight: 500;
          // font-style: normal;
          // line-height: 30px;
          font-size: 2vmax;
          // padding-top: 20px;

          @media (max-width: 1240px) {
            font-size: 1.4vmax;
          }
        }
      }

      .company-img-wrapper {
        display: flex;
        // margin-top: 30px;
        align-items: center;
        flex-wrap: wrap;
        justify-content: center;
        padding: 0 20px;
        .many-more {
          font-size: 14px;
          color: gray;
        }

        .company-img {
          width: auto !important;
          max-height: 70px;
          margin: 20px;
          max-width: 80px;

          @include mobile {
            margin: 10px;
            max-width: 60px;
          }
        }

        @include mobile {
          padding: 0;
        }
      }

      .list-items {
        padding: 0 1rem;
        display: flex;
        // align-items: center;
        justify-content: center;
        ul {
          // width: 80%;
          // font-family: SeoulNamsan;
          font-weight: lighter;
          word-spacing: 2px;
          line-height: 7vh;
          // width: 78%;
          list-style-image: url('../.././assets/Images/AboutUS/Vector.png');
          li {
            padding-left: 15px;
            font-size: 1.6vmax;
          }
        }
      }
      .images {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 20px;
        height: 100%;
        width: 100%;
        grid-column-gap: 10%;
        h3 {
          font-size: 2vmax;
          font-weight: 500;
          margin-bottom: 20px;
        }
        // img{
        //   height: 40%;
        //   width: 60%;
        // }
      }
    }
    .bg-grey {
      background: #ffe9e9;
      color: black;
    }
    @include mobile {
      width: 100%;
      grid-template-columns: 1fr;
      min-height: 60vh;
      text-align: center;
      place-items: center;
      padding: 0%;
    }
  }
}

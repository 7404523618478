@import '../../assets/styles/_variables.scss';

@import '../../assets/styles/_mixins.scss';

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
  background-color: $primaryColor;
  height: $navHeight;
  color: white;

  h2 {
    color: inherit;
  }
}

.solve-assignment {
  display: flex;

  flex-direction: column;
  min-height: 100vh;

  .assignment-details {
    border-bottom: 1px solid black;
    padding: 20px;

    .details {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid black;

      div {
        margin: 10px 0;
        flex: 1;
        text-align: center;
      }
    }

    .submission-details {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid black;

      div {
        margin: 10px 0;
        flex: 1;
        text-align: center;

        a {
          display: block;
          text-transform: uppercase;
          font-weight: bold;
          color: blue;
        }
      }
    }

    .description {
      .header {
        display: flex;
        justify-content: space-between;
        margin: 10px 0;

        button {
          background-color: $secondaryColor;
          color: white;
          border-radius: 5px;

          svg {
            color: white;
            fill: white;
          }
        }
      }
    }
  }

  .body {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;

    .left-div {
      width: 20%;
      overflow-y: scroll;

      h3 {
        padding: 10px 20px;
      }

      .task {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid gainsboro;
        padding: 10px 20px;
        width: 100%;

        &:hover {
          background-color: $lightBackground;
          transition: all 0.5s;

          p {
            color: white;
          }
        }

        .status {
          font-weight: 500;
          font-size: 0.8rem;
          letter-spacing: 0.5px;
        }

        .completed {
          color: green;
        }

        .pending {
          color: red;
        }
      }

      .selected {
        background-color: $secondaryColor;

        p {
          color: white !important;
        }

        color: white;

        svg {
          fill: white;
        }

        -webkit-box-shadow: 0px 0px 30px -4px rgba(0, 0, 0, 0.75);
        -moz-box-shadow: 0px 0px 30px -4px rgba(0, 0, 0, 0.75);
        box-shadow: 0px 0px 30px -4px rgba(0, 0, 0, 0.75);
      }
    }

    .middle-div {
      width: 100%;
      padding: 10px 20px;
      flex-grow: 1;

      h3 {
        text-transform: capitalize;
      }

      .details {
        padding: 20px 0px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        border-bottom: 1px solid black;
        justify-content: space-between;

        div {
          flex-grow: 1;

          p {
            strike {
              font-size: 0.84rem;
              font-weight: bold;
              color: red;
              margin: 0 5px;
            }
          }
        }
      }

      .description {
        border-bottom: 1px solid;
        padding-bottom: 10px;
      }

      .question-div {
        background-color: $secondaryColor;
        padding: 10px;

        & * {
          color: white;
        }

        .question {
          margin: 5px 0;

          h4 {
            font-weight: 500;
            margin: 10px 0;
          }

          p {
            padding: 10px 5px;
            border-radius: 5px;
            background-color: $primaryColor;
            color: black;
            font-size: 0.85rem;
          }
        }
      }
    }
  }

  .grid {
    display: grid;
    grid-template-columns: 2.5fr 1.5fr;
  }

  .btns-div {
    text-align: left;

    button {
      float: left;
    }
  }

  .mt15 {
    margin-top: 13px;
    margin-left: 20px;
  }

  .ml10 {
    margin-left: 10px;
  }

  .btn-red {
    background-color: rgb(192, 9, 9);
    color: white;
  }
}

.flag {
  position: relative;

  .badge {
    position: absolute;
    top: -5px;
    right: 0px;
    padding: 1px 4px;
    border-radius: 50%;
    background-color: $dangerColor;
    color: white;
    font-size: 14px;
  }

  .double {
    right: 0;
  }
}

.user-details {
  .details {
    padding: 10px 20px;
    border-bottom: 1px solid black;

    .content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      p {
        padding: 10px 0;
        font-weight: 500;
      }
    }
  }

  .assignments {
    padding: 10px 20px;

    .assignment-table {
      td,
      th {
        padding: 10px;
      }
    }
  }
}

//UserFlags.js
.user-flag-div {
  .flags {
    width: 100%;
    font-size: 0.9rem;
    margin: 10px 0;
    border: 1px solid black;
    border-collapse: collapse;

    thead {
      font-weight: bold;
      text-align: center;
    }

    td {
      border-bottom: 1px solid black;
      text-align: center;
      padding: 5px;
    }
  }

  input {
    width: 100%;
    margin: 5px 0;
  }
}

.flag-comments-div {
  margin-top: 1rem;
  background-color: blanchedalmond;
  padding: 1rem 2rem;
  border-radius: 10px;

  .quill {
    background-color: white;
    height: 100%;
    margin: 10px 0;

    * {
      color: black;
    }

    .ql-toolbar {
      background-color: rgb(232, 232, 232);
    }
  }

  .comment {
    margin-top: 10px;
    background-color: $primaryColor;
    padding: 1rem;
    border-radius: 10px;

    .comment-head {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid black;

      .user {
        display: flex;
        align-items: center;

        svg {
          width: 1.2rem;
          height: 1.2rem;
        }

        font-size: 0.9rem;
        position: relative;
        padding-bottom: 5px;
      }

      .comment-date {
        font-size: 11px;
        color: rgb(107, 106, 106);
      }
    }

    //   .content {
    //     .ql-editor {
    //       //background-color: black;
    //     }
    //   }
  }
}

@import '../../assets/styles/_variables.scss';

@import '../../assets/styles/_mixins.scss';

.message-page {
  .header {
    padding: 30px 20px 0 60px;

    h3 {
      font-size: 22px;
      font-weight: 400;
    }
  }

  .message-page-content {
    display: grid;
    grid-template-columns: 1fr 3fr;
    padding: 30px;

    .filters-div {
      position: sticky;
      top: $navHeight;

      .filters {
        padding: 10px 10px 25px 10px;
        margin: auto;
        align-items: center;
        margin-top: 50px;
        display: block;
        width: 85%;
        // height: 50vh;
        border-radius: 15px;
        background-color: $secondaryText;
        // background-color: rgb(149, 191, 204);

        .select-div {
          margin-top: 15px;

          h3 {
            font-size: 17px;
            font-weight: 400;
          }

          select {
            outline: none;
            margin-top: 5px;
            padding: 5px;
            border-radius: 10px;
            width: 100%;
          }
        }
      }
    }

    .content-div {
      padding: 0 50px;

      .heading {
        margin: 0;
      }

      .message-form {
        margin-top: 20px;

        .primary-btn {
          margin: 20px 0;
        }
      }
    }
  }
}

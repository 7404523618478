@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_mixins.scss';

.compiler {
  .ace_editor,
  .ace_editor * {
    font-family: 'Monaco', 'Menlo', 'Ubuntu Mono', 'Droid Sans Mono', 'Consolas',
      monospace !important;
    letter-spacing: 0 !important;
  }

  .code-editor {
    width: 100%;
    height: 35vh;
  }

  .run-btn {
  }

  .output {
    width: 100%;

    height: 20vh;
  }
}

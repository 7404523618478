@import './assets/styles/variables';

.error-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: $primaryColor;
  .content {
    width: 50%;
    text-align: center;
    background-color: white;
    padding: 50px;
    border-radius: 10px;

    @media (max-width: 600px) {
      width: 95%;
      padding: 50px 10px;
    }
    a {
      font-weight: bolder;
    }
    .button {
      color: black;
      font-weight: bold;
      border: 1px solid $primaryColor;
      padding: 10px;
      text-transform: uppercase;
      text-decoration: none;
      border-radius: 5px;
    }
  }
}

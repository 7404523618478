@import '../../assets/styles/_variables.scss';
@import '../../assets/styles/_mixins.scss';

.nav-bar {
  height: 10vh;
  background-color: $primaryColor !important;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 0 40px;

  * {
    color: white;
    fill: white;
  }

  h6 {
    font-size: 19px;

    text-transform: capitalize;
  }
}

.sharpener-logo {
  background-image: 'linear-gradient(90deg, #f40b0bcc, #000c 87.19%)';
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
}

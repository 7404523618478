@import '../../assets/styles/variables';

.p1 {
  padding: 1rem;
}

.mt-1 {
  margin-top: 1rem;
}

.card {
  border: 1px solid #ccc;
  border-radius: 8px;
  margin-bottom: 20px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;

  .card-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 1.2rem;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .card-body {
    .date-time {
      display: flex;
      align-items: center;
      color: #555;
      margin-bottom: 8px;

      .icon {
        margin-right: 5px;
        vertical-align: middle;
      }

      .day,
      .time {
        margin-right: 15px;
        display: flex;
        align-items: center;
      }
    }

    .contact-info {
      display: flex;
      align-items: center;
      color: #555;

      .icon {
        margin-right: 5px;
        vertical-align: middle;
      }

      .whatsapp,
      .phone,
      .email {
        margin-right: 15px;
        display: flex;
        align-items: center;
      }
    }

    .action-buttons {
      margin-top: 15px;

      button {
        padding: 8px 12px;
        margin-right: 10px;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 0.9rem;
        transition: background-color 0.2s, color 0.2s;

        &.add-link-button {
          background-color: #3498db;
          color: #fff;
        }

        &.start-appointment-button {
          background-color: #e67e22;
          color: #fff;
        }

        &.passed-button {
          background-color: #27ae60;
          color: #fff;
        }

        &.failed-button {
          background-color: #e70303;
          color: #fff;
        }

        &.absent-button {
          background-color: #2761ae;
          color: #fff;
        }

        &.reallot-button {
          background-color: #221919;
          color: #fff;
        }

        &:hover {
          opacity: 0.9;
        }
      }
    }
  }
}

.disabled-button {
  padding: 8px 12px;
  margin-right: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 0.9rem;
  transition: background-color 0.2s, color 0.2s;
  background-color: #5a5551;
  color: #fff;
}

.join-btn {
  background-color: $primaryColor;
  padding: 8px 12px;
  margin-right: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 0.9rem;
  transition: background-color 0.2s, color 0.2s;
}

.report-btn {
  background-color: blue;
  padding: 8px 12px;
  margin-right: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 0.9rem;
  transition: background-color 0.2s, color 0.2s;
}

.start-btn {
  background-color: blue;
  padding: 8px 12px;
  margin-right: 10px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 0.9rem;
  transition: background-color 0.2s, color 0.2s;
}

@import '../../assets/styles/_variables.scss';

@import '../../assets/styles/_mixins.scss';

.courses-page {
  // background: #2193b0;
  /* fallback for old browsers */
  // background: -webkit-linear-gradient(to bottom,
  // 		#6dd5ed,
  // 		#2193b0);
  // /* Chrome 10-25, Safari 5.1-6 */
  // background: linear-gradient(to bottom,
  // 		#6dd5ed,
  // 		#2193b0);
  /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

  height: 100vh;

  .new-course {
    select {
      border-radius: 10px;
      margin-right: 1rem;
    }

    padding: 20px;
    // text-align: right;
    display: flex;
    justify-content: justify-between;
    align-items: center;

    a {
      align-items: center;
      display: flex;
    }

    .primary-btn {
      color: white;
      padding: 5px 10px;
    }
  }

  .all-courses {
    padding: 2rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }
}

.course {
  word-wrap: break-word;
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  // width: 550px;

  h3 {
    padding: 10px 0;
    font-weight: 500;
    font-size: 1.5rem;
  }

  .actions {
    margin-top: auto;
    padding: 15px 10px 5px 10px;
    display: flex;
    justify-content: space-evenly;
  }
}

.create-course-page {
  padding: 40px;

  .header {
    display: flex;
    justify-content: space-between;

    a {
      display: flex;
      color: $text;
      align-items: center;
      place-items: center;
      font-style: italic;
    }
  }

  .body {
    .input {
      input {
        display: block;
        width: 100%;
        margin-top: 10px;
        padding: 10px;
      }

      ::placeholder {
        color: $text;
      }
    }

    .course-description {
      margin-top: 10px;

      input {
        border: none;
        width: 100%;
        height: 100%;
        box-sizing: border-box;
        padding: 10px;
      }
    }

    .fees {
      margin: 10px 0;
      margin-right: 10px;

      input {
        width: 100px;
        border: 2px;
        border-bottom: 2px solid black;
        display: inline-block;
        padding: 10px;
        text-align: center;
      }

      span {
        font-weight: bold;
      }
    }

    .select {
      margin-top: 20px;
      margin-right: 20px;
    }

    .switch {
      display: flex;
      margin: 30px 0;
      align-items: center;

      label {
        margin: 0 10px 0 0;
      }

      p {
        font-weight: bold;
      }
    }

    .course-time-div {
      border-top: 1px solid black;
      margin-top: 20px;
      padding-top: 10px;

      .content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

        align-items: flex-start;
        grid-column-gap: 25px;
        margin: 20px 0;

        .date-time-div {
          margin: 0px;
          position: relative;

          place-items: center;
        }

        .time-input {
          margin: 10px 0;
          border-bottom: 1px solid black;

          padding-bottom: 10px;

          b,
          select {
            font-size: 0.9rem;
          }

          input {
            margin: 10px 0;

            font-size: 0.9rem;
          }

          button {
            border-radius: 50%;
            width: auto;
            height: auto;

            svg {
              width: 25px;
              height: 25px;
            }

            display: grid;
            place-items: center;
          }
        }

        .classes {
          margin-top: 20px;

          .class {
            // display: flex;
            border-bottom: 1px dashed $text;
            margin: 10px 0;
            padding-bottom: 5px;

            p,
            a {
              font-size: 0.85rem;
            }

            .actions {
              display: flex;
              align-items: center;
              justify-content: space-between;

              button {
                margin: 0;

                svg {
                  width: 20px;
                  height: 20px;
                }
              }
            }
          }
        }
      }
    }
  }
}

//   background-color: $lightBackground;
//   border: 1px solid $lightBackground;
//   padding: 5px 20px 10px 20px;
//   border-radius: 10px;
//   box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

//   h3 {
//     padding: 5px 0px 10px 0px;
//   }
//   .actions {
//     padding: 20px 10px 0px 10px;
//     display: flex;
//     justify-content: space-evenly;
//   }
// }

.create-course-page {
  .header {
    display: flex;
    justify-content: space-between;

    a {
      display: flex;
      color: black;
      align-items: center;
      place-items: center;
    }
  }
}

.course-plan-page {
  height: 90vh;

  background: $background;
  // background: -webkit-linear-gradient(to bottom, #636fa4, #e8cbc0);
  // background: linear-gradient(to bottom, #636fa4, #e8cbc0);

  .header {
    padding: 30px 4rem 0px 4rem;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .primary-btn {
      color: white;
      padding: 5px 10px;
    }

    svg {
      fill: white;
    }

    a {
      display: flex;
      align-items: center;
      place-items: center;
    }

    h3 {
      font-weight: 400;
      font-size: 20px;
    }
  }

  .body {
    width: 50%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  .assignments {
    width: 90%;
    margin: auto;
    padding: 0rem 1rem 0.5rem 1rem;
    margin-top: 25px;
    height: 85vh;
    overflow-y: auto;

    p {
      color: $text;
    }

    .assignment {
      padding: 20px 10px;
      border-radius: 10px;
      background-color: $lightBackground;
      box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

      .flex-div {
        padding: 15px 5px;
        display: grid;
        grid-template-columns: 1.5fr 1fr 1fr;
        align-items: center;

        .flex {
          // width: 500px;
          align-items: center;
          justify-content: flex-start;
          grid-column-gap: 20px;
        }

        .primary-btn {
          // text-align: left;
          // width: 25%;
          align-items: center;
          background-color: $secondaryColor;
        }
      }
    }

    .assignment:not(:first-child) {
      margin-top: 10px;
    }
  }
}

.all-assigns {
  width: 700px;

  .assign {
    padding: 10px;
    align-items: center;
    border-bottom: 1px solid black;
    display: flex;
    justify-content: space-between;
  }
}

.course-overview {
  // width: 50%;

  .header {
    display: flex;
    padding: 10px 20px;
    border-bottom: 1px solid black;
  }

  .head {
    padding: 10px;
    display: block;

    h4 {
      padding: 5px;
    }
  }

  .table-div {
    margin-top: 10px;

    table {
      width: 100%;
      border-collapse: collapse;

      th {
        background-color: $secondaryColor;
        color: white;
      }

      tr {
        th,
        td {
          padding: 10px;
          height: 100%;
          text-align: center;
          font-size: 0.85rem;
        }
      }

      tr:nth-child(even) {
        background-color: $lightBackground;
      }

      .btn {
        outline: none;
        margin: auto;
        padding: 10px;
        width: 100%;
        height: 100%;
        color: black;
      }
    }
  }
}

.assignments,
.table-div {
  scroll-behavior: smooth;
  font-family: 'Inter', sans-serif;

  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: $primaryColor;
    background: $lightBackground;
    border-radius: 15px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $lightBackground;
    background: $primaryColor;
    border-radius: 15px;
    cursor: pointer;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.course-students {
  padding: 0 1rem;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

label {
  font-weight: 500;
}

.add-assignment-scrollbar {
  &::-webkit-scrollbar {
    display: none;
  }
}

.sharpener-gradient-background {
  background: linear-gradient(90deg, #f40b0bcc, #000c 87.19%);
}

.nav-bar {
  height: 10vh;
  background-color: #8c4848 !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 40px;
}

.nav-bar * {
  color: white;
  fill: white;
}

.nav-bar h6 {
  font-size: 19px;
  text-transform: capitalize;
}

.sharpener-logo {
  background-image: linear-gradient(90deg, #f40b0bcc, #000c 87.19%);
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  -webkit-text-fill-color: transparent;
  font-size: 1.2rem;
  font-weight: bold;
}

.sharpener-gradient-background {
  background: linear-gradient(90deg, #f40b0bcc, #000c 87.19%);
}
